@use '../config/' as *;
.waf-event-participate {
    padding-block: var(--space-10);
    margin-block: var(--space-24);
    @extend %relative;
    @extend %border-radius-m;
    @extend %px-4;
    @extend %mx-2;
    @include border(1, neutral-20);
    &::before {
        content: '';
        @extend %zindex-event-participate-bg;
        @extend %w-100;
        @extend %h-100;
        @extend %position-t-l;
        @include bgImg("cssimages/pattern/blue-gradient-left.png", left, cover);
    }
    .layout-wrapper {
        max-width: var(--content-width);
        background-color: hsla(var(--hsl-neutral-0)/30%);
        &::after {
            content: '';
            height: 50rem;
            pointer-events: none;
            @extend %zindex-event-participate-bg;
            @extend %w-100;
            @extend %position-t-l;
            @include bgImg("svg/circle-ball.svg", center, cover);
        }
    }
    .waf-head {
        .title {
            line-height: 1.2;
            @extend %mb-12;
            @extend %title-40;
            @extend %neutral-100;
        }
    }
    .card {
        &-section {
            @extend %gap-10;
            @include flex-config(flex, column-reverse);
        }
        &-info {
            .title {
                @extend %capitalize;
                @extend %title-50;
                @extend %neutral-100;
                @extend %mb-6;
            }
            .text {
                @extend %body-l;
                @extend %neutral-100;
                @extend %mb-4;
            }
        }
        &-rule {
            &-list {
                @extend %gap-10;
                @extend %flex-column;
            }
            &-item {
                @extend %flex;
                @extend %relative;
                &:not(:last-child) {
                    &:after {
                        content: "";
                        width: 0.1rem;
                        @extend %h-100;
                        @extend %bg-neutral-20;
                        @include position(var(--space-10), null, null, var(--space-5));
                    }
                }
            }
            .number {
                width: 4rem;
                height: 4rem;
                flex-shrink: 0;
                @extend %zindex-eventborder;
                @extend %circle-radius;
                @extend %bg-neutral-0;
                @extend %neutral-100;
                @extend %body-l;
                @extend %flex-c-c;
            }
            .text {
                @extend %neutral-100;
                @extend %body-l;
            }
            .label {
                @extend %pl-6;
            }
        }
        &-search {
            @extend %relative;
            &.active {
                .search-wrapper {
                    border-bottom: .1rem solid var(--focus-0);
                }
                .search-info {
                    display: block;
                }
            }
            .form-label {
                @extend %neutral-100;
                @extend %body-lb;
            }
            .form-control {
                @extend %neutral-100;
                @extend %w-100;
            }
            .no-result {
                @extend %neutral-100;
                @extend %body-l;
                @extend %p-4;
            }
        }
    }
    .search {
        &-wrapper {
            height: 7.2rem;
            @extend %flex-sb-c;
            @extend %mt-6;
            @extend %py-6;
            @extend %px-4;
            @extend %w-100;
            @extend %bg-neutral-0;
            @extend %body-l;
            @include border(1, neutral-20, 10, bottom);
        }
        &-info {
            border-radius: 0 0 1.6rem 1.6rem;
            @extend %zindex-searchlist;
            @extend %w-100;
            @extend %d-none;
            @extend %bg-neutral-0;
            @include position(null, null, null, 0);
            .title {
                @extend %capitalize;
                @extend %neutral-100;
                @extend %body-mb;
                @include truncate-text(1);
            }
        }
        &-info-item {
            @extend %p-4;
            @extend %relative;
            @extend %flex-column;
            &:not(:last-child) {
                @include border(1, neutral-20, 10, bottom);
            }
            &::after {
                transform: rotate(-45deg);
                @include position(null, var(--space-4), var(--space-4), null);
                @include icon(arrow-small-right, 20, null);
            }
        }
        &-info-origin {
            @extend %gap-5;
            @extend %flex-n-c;
            .image {
                width: 2.4rem;
                height: 2.4rem;
                object-fit: cover;
                @extend %circle-radius;
            }
            .text {
                @extend %relative;
                @extend %capitalize;
                @extend %neutral-70;
                @extend %body-m;
                &:before {
                    content: '';
                    width: .4rem;
                    height: .4rem;
                    left: var(--space-3-neg);
                    @extend %flex;
                    @extend %circle-radius;
                    @extend %position-y-center;
                    @extend %bg-neutral-20;
                }
            }
        }
    }
    .search-wrapper:has(.search-text) {
        @extend %pr-15;
        ~ .btn-close {
            @extend %flex-c-c;
        }
    }
    .btn-close {
        @include position(7.2rem, 1.6rem);
        @extend %d-none;
        @extend %neutral-100;
        &::after {
            content: "Clear";
            @extend %primary-50;
            @extend %body-mb;
        }
        &:hover{
            &::after{
                @extend %primary-55;
            }
          }
          &:active{
            &::after{
                @extend %primary-85;
            }
          }
        .btn-text {
            @extend %font-0;
        }
    }
}
@include mq(col-tablet) {
    .waf-event-participate {
        padding: var(--space-0);
        margin-inline: var(--space-0);
        max-width: var(--container-max-width);
        margin-inline: auto;
        position: unset;
        .layout-wrapper {
            padding: var(--space-10) var(--space-10);
            max-width: var(--container-max-width);
            position: relative;
            &::after {
                width: 42rem;
                height: 50rem;
                background-position: -9rem -6rem;
            }
        }
        .waf-head {
            .title {
                width: 50%;
                font-size: 4rem;
            }
        }
        .card {
            &-section {
                gap: var(--space-0);
                flex-direction: row;
            }
            &-rule,
            &-content {
                width: 50%;
            }
            &-rule {
                padding-left: var(--space-10);
            }
        }
    }
}
@include mq(col-lg) {
    .waf-event-participate {
        .layout-wrapper {
            padding: var(--space-15) var(--space-20);
        }
        .waf-head {
            .title {
                width: 50%;
            }
        }
        .card-rule {
            padding-left: var(--space-24);
        }
    }
}